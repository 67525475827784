import Vue from 'vue';

Vue.component('inline-value-edit', {
    template: `
<div class="form-group">
    <textarea rows="3" v-show="state === 'editing'" v-model="value" class="form-control" ref="editField">
    </textarea>
    <p v-if="state === 'display'" v-html="value" class="d-inline-block"></p>
    <i v-if="state === 'display'" class="fa fa-pencil-square-o" @click="state = 'editing'" style="cursor: pointer; font-size: 1.5em"></i>
    <i v-if="state === 'editing'" class="fa fa-save" @click="save" style="cursor: pointer; font-size: 1.5em"></i>
    <i v-if="state === 'editing'" class="fa fa-window-close" @click="state = 'display'" style="cursor: pointer; font-size: 1.5em"></i>
</div>
`,
    props: {
        url: {
            required: true,
            type: String
        },
        method: {
            required: false,
            type: String,
            default: 'POST'
        },
        settingValue: {
            required: false,
            type: String,
            default: ''
        }
    },
    data() {
        return {
            state: 'display',
            value: ''
        }
    },
    mounted() {
        this.value = this.settingValue;
    },
    methods: {
        save() {
            let _self = this;

            $.ajax({
                method: this.method,
                url: this.url,
                data: {value: this.value},
                success() {
                    _self.state = 'display';
                    flash('Update was successful.', 'success');
                },
                error(error) {
                    _self.state = 'display';
                    _self.value = _self.settingValue;
                    flash(error['responseJSON'].detail, 'error');
                }
            });
        }
    },
    watch: {
        state: function(newState) {
            if (newState === 'editing') {
                this.$nextTick(() => {
                    this.$refs.editField.focus();
                    this.$refs.editField.select();
                });
            }
        }
    }
});