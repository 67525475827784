import Vue from 'vue';
import Sortable from "sortablejs";

Vue.component('poll', {
    delimiters: ['[[', ']]'],
    template: '#poll',
    props: {
        pollId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            poll: {questions: []},
            newQuestion: {type: 'single_answer'},
            isAdding: false,
            sortable: null
        }
    },
    created() {
        this.getData();
    },
    mounted() {
        this.initSortable();
    },
    methods: {
        getCreateData() {
            return {
                text: this.newQuestion.text,
                type: this.newQuestion.type,
                pollId: this.poll.id
            }
        },

        getData() {
            let url = `/admin/polls/${this.pollId}`,
                _self = this;

            $.ajax({
                method: 'GET',
                url: url,
                success(response) {
                    _self.poll = response;
                },
                error(error) {
                    console.log(error);
                }
            });
        },

        onQuestionDeleted(id) {
            let index = this.poll.questions.findIndex(item => item.id === id);

            this.poll.questions.splice(index, 1);
        },

        createQuestion() {
            let url = `/admin/questions/new`,
                data = this.getCreateData(),
                _self = this;

            $.ajax({
                method: 'POST',
                data: data,
                url: url,
                success(response) {
                    let question = response['payload'];

                    question['answers'] = [];

                    _self.isAdding = false;

                    _self.poll.questions.push(question);

                    _self.initSortable();

                    _self.resetQuestion();

                    flash(response['message'], 'success');
                },
                error(error) {
                    console.log(error);
                }
            });
        },

        resetQuestion() {
            this.newQuestion = {
                text: '',
                type: 'single_answer',
                questions: []
            };
        },

        initSortable() {
            let _self = this;

            this.sortable = Sortable.create(this.$refs.questions, {
                ghostClass: 'blue-background-during-drag',
                'handle': '.sort-handle',
                animation: 300,
                onSort: function () {
                    let data = [];
                    _self.$refs.questions.children.forEach(item => data.push(item.getAttribute('data-id')));

                    _self.sortQuestions({sortedQuestions: data});
                }
            });
        },

        sortQuestions(data) {
            let url = `/admin/polls/${this.poll.id}/sort-questions`;

            $.ajax({
                method: 'POST',
                data: data,
                url: url,
                success(response) {
                    flash(response, 'success');
                },
                error(error) {
                    console.log(error);
                }
            });
        }
    }
});