import Vue from 'vue';
// import ionRangeSlider from 'ion-rangeslider/js/ion.rangeSlider';
// import Slider from 'bootstrap-slider';
// import 'bootstrap-slider/dist/css/bootstrap-slider.min.css';
// import 'ion-rangeslider/css/ion.rangeSlider.css';
// import  './../../../assets/js/jquery-ui-slider-pips';
Vue.component('credit-calculator', {
    delimiters: ['[[', ']]'],
    template: '#credit-calculator',
    props: {
        currency: {
            required: false,
            type: String,
            default: 'лв.'
        },
        isFormValid: {
            required: true,
            type: String,
            default: '1'
        }
    },
    data() {
        return {
            allCreditsData: "",
            currCreditInfo: "",
            amountSlider: "",
            periodSlider: "",
            isRefreshing: false,
            amountSteps: [
                100,
                200,
                300,
                400,
                500,
                600,
                700,
                800,
                900,
                1000,
                1100,
                1200,
                1300,
                1400,
                1500,
                1600,
                1700,
                1800,
                1900,
                2000,
                2100,
                2200,
                2300,
                2400,
                2500,
                2600,
                2700,
                2800,
                2900,
                3000,
            ],
            periodsByAmount: {},

            currPeriods: [],
            amount: 400,
            period: 4,
            payment: "108,47",
            paymentWithPenalty: "132,11",
            total: "433,88",
            totalWithPenalty: "528,44",
            minAmount: 100,
            maxAmount: 3000,
            minPeriod: 2,   // the periods for amount = 1000лв.
            maxPeriod: 9,
            annualInterestRate: 40.00,
            aprc: 49.03,
            creditDataId: 0,

            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            // agree_call: 0,
            terms: 0,

            firstNameError: "",
            lastNameError: "",
            phoneError: "",
            emailError: "",
            termsError: "",
            // type: 'personal',
            // payment: 1518.00,
            // paymentWithPenalty: 1905.00,
            // periods: 2,
            // total: 3036.00,
            // totalWithPenalty: 3810.00,
            // interestRateFirstPeriod: 0.959,
            // interestRateSecondPeriod: 0.959,
            // aprc: 0.1017,
            // paymentSchedule: 'monthly',
            // pensioner: 'no',
            // creditTitle: '',
            // creditSlug: '',
            // requirements: '',
            default: null
        }
    },
    mounted() {
        this.getRemoteData();

        if(this.isFormValid == 0) {
            $('#errors').modal();
        }

        EventManager.listen('amount-changed', this.onAmountChange);
        EventManager.listen('period-changed', this.onPeriodChange);
    },
    methods: {
        onAmountChange: function (amountValue) {
            this.amount = amountValue;

            this.setCurrCreditInfo();
            this.initPaymentSlider();
        },

        onPeriodChange: function (periodValue) {
            this.period = periodValue;

            this.setCurrCreditInfo();
        },

        setCurrCreditInfo: function () {
            var self = this;

            this.currPeriods = this.periodsByAmount[this.amount];
            this.minPeriod = this.currPeriods[0];
            this.maxPeriod = this.currPeriods[this.currPeriods.length - 1];

            if(this.currPeriods.includes(this.period) === false) {
                this.period = this.currPeriods[0];
            }

            // this.currCreditInfo = this.allCreditsData[this.amount].filter(p => p.periods === this.period)[0];
            this.currCreditInfo = this.allCreditsData[this.amount].filter(function(p) {
                if(p.periods === self.period) {
                    return p;
                }
            });

            this.currCreditInfo = this.currCreditInfo[0];


            if(this.currCreditInfo !== "undefined") {
                this.payment = this.currCreditInfo.payment.replace('.', ',');
                this.total = this.currCreditInfo.total.replace('.', ',');
                this.paymentWithPenalty = this.currCreditInfo.paymentWithPenalty.replace('.', ',');
                this.totalWithPenalty = this.currCreditInfo.totalWithPenalty.replace('.', ',');
                this.annualInterestRate = this.currCreditInfo.annualInterestRate;
                this.aprc = this.currCreditInfo.aprc;
                this.creditDataId = this.currCreditInfo.id
            }
        },

        defaultParams() {
            return {
                amount: 1000.00
            }
        },

        getRemoteData(params = this.defaultParams(), creditHasChanged = false) {
            let url = '/credit-data?' + $.param(params),
                _self = this;

            this.isRefreshing = true;

            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {

                    _self.allCreditsData = response;
                    // _self.amountSteps = Object.keys(response).map(value => parseFloat(value));
                    _self.amountSteps = Object.keys(response).map(function(value) {
                        return parseFloat(value);
                    })

                    Object.values(response).map(function(periodsArr, index)  {
                        periodsArr.map(function(p) {

                            if(!_self.periodsByAmount[parseFloat(p.amount)]) {
                                _self.periodsByAmount[parseFloat(p.amount)] = [];
                            }

                            _self.periodsByAmount[parseFloat(p.amount)].push(p.periods);

                            _self.periodsByAmount[parseFloat(p.amount)].sort(function(a, b) {
                                return a - b;
                            });
                        })
                    });

                    _self.currCreditInfo = response[_self.amount].filter(function(p) {
                        if (p.periods === _self.period) {
                            return p;
                        }
                    })


                    if(_self.currCreditInfo[0]) {
                        _self.currCreditInfo = _self.currCreditInfo[0];
                        _self.creditDataId = _self.currCreditInfo['id'];
                    }

                    _self.initAmountSlider();
                    _self.initPaymentSlider();
                },
                error: function (response) {
                    console.log(response);
                }
            });
        },

        initAmountSlider() {
            const self = this;
            let index = self.amountSteps.findIndex(function(value) {
                if(value === self.defaultAmount) {
                    return value;
                }
            });

            $(this.$refs.amountSlider).slider({
                min: self.minAmount,
                max: self.maxAmount, //this.amountSteps.length,
                step: 100,
                value: self.amountSteps[index],
                // values: this.amountSteps,
                range: "min",
                slide: function(event, ui) {
                    EventManager.fire('amount-changed', ui.value);
                },
                change: function(event, ui) {
                    EventManager.fire('amount-changed', ui.value);
                    // EventManager.fire('amount-changed', ui.value);
                    // $(".ui-slider-tip").html(self.amountSteps[ui.value-1]);
                },
                create: function (event, ui) {
                    // $(".ui-slider-tip").innerText = self.amountSteps[index];
                    // console.log("CREATED ", self.amountSteps[index])
                }
            }).slider("float", {
                suffix: " лв."
            })
                .slider("pips", {
                    rest: "label",
                    labels: this.amountSteps,
                    step: 6
                });
        },

        initPaymentSlider() {
            const self = this;
            // let periodSlider = $(this.$refs.periodSlider).data("ionRangeSlider"),
            //     index = this.currPeriods.findIndex(value => value.toFixed(2) === this.defaultAmount);

            // let index = self.currPeriods.findIndex(value => value === this.defaultAmount);

            // if (amountSlider) {
            //     amountSlider.destroy();
            // }

            // console.log($(this.$refs.periodSlider).slider('destroy'));
            // return;

            $(this.$refs.periodSlider).slider({
                min: self.minPeriod,
                max: self.maxPeriod,
                step: 1,
                value: self.period,
                range: "min",
                slide: function(event, ui) {
                    EventManager.fire('period-changed', ui.value);
                    // $(".ui-slider-tip").html(self.amountSteps[ui.value-1]);
                },
                change: function(event, ui) {
                    EventManager.fire('period-changed', ui.value);
                },
            }).slider("float", {
                suffix: " месеца"
            })
                .slider("pips", {
                    rest: "label",
                    step: 1 //Math.round((self.minPeriod + self.maxPeriod) /5)
                });
        },

        applyForCredit() {

            // console.log("CURR  ", this.currCreditInfo);return;
            let url = '/credit-data/apply',
                _self = this;

            let postData = {};
            postData.firstName = this.firstName;
            postData.lastName = this.lastName;
            postData.phone = this.phone;
            postData.email = this.email;
            postData.terms = this.terms;
            postData.creditDataId = this.currCreditInfo.id;
            // postData.agree_call = this.agree_call;

            this.isRefreshing = true;

            $.ajax({
                url: url,
                method: 'POST',
                data: postData,
            })
            .then(function(response) {
                    console.log(response);
                    if(response.success) {
                        if(response.payload && response.payload.redirectLink) {
                            window.location = response.payload.redirectLink;
                        }
                    }
            })
            .catch(function(error) {
                let resp = error.responseJSON;
                if(resp.errors) {
                    Object.keys(resp.errors).map(function(k) {
                        let fieldErrorName = k + "Error";
                        _self[fieldErrorName] = resp.errors[k];
                        alert(resp.errors[k]);
                    })
                }
            })
        },

        // OLD CODE FROM HERE ..


        getDataOld(params = this.defaultParams(), creditHasChanged = false) {
            let url = '/credit-data?' + $.param(params),
                _self = this;

            this.isRefreshing = true;

            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {
                    _self.amountSteps = response.amountSteps.map(function(value) {
                        return parseFloat(value);
                    });
                    _self.paymentSteps = response.paymentSteps.map(function(value) {
                        return parseFloat(value);
                    });

                    _self.amount = response.creditData.amount;
                    _self.payment = response.creditData.payment;
                    _self.paymentWithPenalty = response.creditData.paymentWithPenalty;
                    _self.periods = response.creditData.periods;
                    _self.total = response.creditData.total;
                    _self.totalWithPenalty = response.creditData.totalWithPenalty;
                    _self.interestRateFirstPeriod = response.creditData.interestRateFirstPeriod;
                    _self.interestRateSecondPeriod = response.creditData.interestRateSecondPeriod;
                    _self.aprc = response.creditData.aprc;
                    _self.creditTitle = response.creditData.credit.title;
                    _self.creditSlug = response.creditData.credit.slug;
                    _self.requirements = response.creditData.credit.requirements;
                    _self.default = response.creditData.credit.defaultAmount;

                    _self.isRefreshing = false;

                    if (creditHasChanged) {
                        _self.initAmountSlider();
                    }

                    _self.initPaymentSlider();
                },
                error: function (response) {
                    console.log(response);
                }
            });
        },

        amountChanged(value) {
            this.amount = value.toFixed(2);

            let parameters = {
                amount: this.amount,
                pensioner: this.pensioner,
                paymentSchedule: this.paymentSchedule,
                type: this.type
            };

            this.getData(parameters);
        },

        paymentChanged(value) {
            this.paymentWithPenalty = value.toFixed(2);

            let parameters = {
                payment: this.paymentWithPenalty,
                amount: this.amount,
                pensioner: this.pensioner,
                paymentSchedule: this.paymentSchedule,
                type: this.type
            };

            this.getData(parameters);
        },

        paymentScheduleChanged() {
            if (this.paymentSchedule === 'monthly' && this.type === 'personal') {
                this.pensioner = 'no';
            }

            let parameters = {
                paymentSchedule: this.paymentSchedule,
                type: this.type,
                pensioner: this.pensioner,
                amount: this.defaultAmountForPaymentSchedule
            };

            this.getData(parameters, true);
        },

        pensionerChanged() {
            let parameters = {};

            if (this.pensioner === 'yes') {
                this.paymentSchedule = 'monthly';

                parameters = {
                    paymentSchedule: this.paymentSchedule,
                    type: this.type,
                    pensioner: this.pensioner,
                    amount: this.defaultAmountForPaymentSchedule
                };
            } else {
                this.paymentSchedule = 'monthly';

                parameters = {
                    paymentSchedule: this.paymentSchedule,
                    type: this.type,
                    pensioner: this.pensioner,
                    amount: this.defaultAmountForPaymentSchedule
                };
            }

            this.getData(parameters, true);
        },

        changeType(newType) {
            if (this.type === newType) {
                return;
            }

            this.type = newType;

            if (newType === 'business') {
                this.pensioner = 'no';
            }

            this.paymentSchedule = 'weekly';

            let parameters = {
                paymentSchedule: this.paymentSchedule,
                type: this.type,
                pensioner: this.pensioner
            };

            if (newType === 'business') {
                parameters.amount = 1600.00;
                parameters.payment = 92.00;
            }

            this.getData(parameters, true);
        },

        initPaymentSliderOld() {
            var self = this;
            let paymentSlider = $(this.$refs.payment).data("ionRangeSlider"),
                adjustedPaymentSteps = this.paymentSteps;

            if (paymentSlider) {
                paymentSlider.destroy();
            }

            if (adjustedPaymentSteps.length === 1) {
                adjustedPaymentSteps.push(adjustedPaymentSteps[0]);
            }

            let index = this.paymentSteps.findIndex(function(value) {
                if(value.toFixed(2) === self.payment) {
                    return value.toFixed(2);
                }
            });

            $(this.$refs.payment).ionRangeSlider({
                skin: 'flat',
                grid: true,
                grid_snap: true,
                values: adjustedPaymentSteps,
                from: index,
                postfix: ' ' + this.currency,
                force_edges: false,
                onFinish: function (data) {
                    EventManager.fire('payment-changed', data.from_value);
                }
            });

            paymentSlider = $(this.$refs.payment).data("ionRangeSlider");
            paymentSlider.update({from: index});
        },

        createOffer() {
            EventManager.fire('create-credit-offer', {
                type: this.type,
                amount: this.amount,
                payment: this.payment,
                paymentWithPenalty: this.paymentWithPenalty,
                periods: this.periods,
                duration: this.duration,
                total: this.total,
                totalWithPenalty: this.totalWithPenalty,
                paymentSchedule: this.paymentSchedule,
                interestRateFirstPeriod: this.interestFirstPeriod,
                interestRateSecondPeriod: this.interestSecondPeriod,
                aprc: this.interestAPRC,
                pensioner: this.pensioner,
                title: this.creditTitle,
                slug: this.creditSlug,
                requirements: this.requirements,
            });
        },

        apply() {
            window.location.href = `/credit/apply/${this.creditSlug}?amount=${this.amount}`;
        }
    },

    computed: {
        duration() {
            if (this.paymentSchedule === 'weekly') {
                return Math.ceil(this.periods / 4.33);
            } else if (this.paymentSchedule === 'biweekly') {
                return Math.ceil(this.periods / 2);
            } else {
                return this.periods;
            }
        },

        interestFirstPeriod() {
            return (this.interestRateFirstPeriod * 100).toFixed(2) + '%';
        },

        interestSecondPeriod() {
            return (this.interestRateSecondPeriod * 100).toFixed(2) + '%';
        },

        interestAPRC() {
            return (this.aprc * 100).toFixed(2) + '%';
        },

        defaultAmount() {
            return this.default === null ? this.amount : this.default;
        },
        defaultAmountForPaymentSchedule() {
            if (this.paymentSchedule === 'weekly') {
                return 3000.00;
            }

            if (this.paymentSchedule === 'biweekly') {
                return 3000.00;
            }
            if (this.paymentSchedule === 'monthly') {
                return 3000.00;
            }

        }
    },
    watch: {
        type: function () {
            EventManager.fire('credit-type-changed');
        }
    }
});