import Vue from 'vue';

Vue.component('image-gallery', {
    delimiters: ['[[', ']]'],
    template: '#image-gallery',
    props: {
        componentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            images: [],
            modalElement: null,
            isLoading: true,
            uploading: false
        }
    },
    mounted() {
        this.modalElement = this.$el.querySelector('#image-gallery-modal');

        $(this.modalElement).on('hidden.bs.modal', this.closeGallery);

        this.openGallery();
    },
    methods: {
        getImages() {
            let url = '/admin/image',
                _self = this;

            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {
                    _self.images = JSON.parse(response);
                    _self.isLoading = false;
                },
                error: function (response) {
                    console.log(response);
                }
            });
        },

        openGallery() {
            $(this.modalElement).modal('show');
            this.getImages();
        },

        closeGallery() {
            $(this.modalElement).modal('hide');
            EventManager.fire('remove-component', this.componentId);
        },

        selectImage(publicPath) {
            EventManager.fire('image-gallery.select-image', {path: publicPath, componentId: this.componentId});

            this.closeGallery();
        },

        selectFile() {
            this.$refs.fileInput.click();
        },

        uploadFile() {
            let file = this.$refs.fileInput.files[0],
                _self = this;

            if (!file) {
                return;
            }

            this.uploading = true;

            let formData = new FormData();
            formData.append("file", file);

            $.ajax({
                method: 'post',
                url: '/admin/files/image/create?flash=0',
                data: formData,
                processData: false,
                contentType: false,
                success(response) {
                    _self.uploading = false;
                    _self.images.unshift(response);
                },
                error(error) {
                    _self.uploading = false;

                    flash(error['responseJSON'].detail, 'error');
                }
            });
        }
    },

    computed: {
        isUploading() {
            return this.uploading === true;
        }
    }
});