import {addListToDropdown, createDropdown} from '@ckeditor/ckeditor5-ui/src/dropdown/utils';

import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';

export default class WidgetsUI {
    constructor(editor) {
        this.editor = editor;
    }

    init() {
        const editor = this.editor;

        // The "placeholder" dropdown must be registered among the UI components of the editor
        // to be displayed in the toolbar.
        editor.ui.componentFactory.add('widgets', locale => {
            const dropdownView = createDropdown(locale);

            // Populate the list in the dropdown with items.
            addListToDropdown(dropdownView, getDropdownItemsDefinitions());

            dropdownView.buttonView.set({
                label: 'Widgets',
                tooltip: true,
                withText: true
            });

            // Execute the command when the dropdown item is clicked (executed).
            dropdownView.on('execute', (evt) => {
                editor.execute(evt.source.commandParam);
                editor.editing.view.focus();
            });

            return dropdownView;
        });
    }
}

function getDropdownItemsDefinitions() {
    const itemDefinitions = new Collection();

    const imageDefinition = {
        type: 'button',
        model: new Model({
            commandParam: 'insertImageViaURL',
            label: 'Image via URL',
            withText: true
        })
    };

    // Add the item definition to the collection.
    itemDefinitions.add(imageDefinition);

    const applyButtonDefinition = {
        type: 'button',
        model: new Model({
            commandParam: 'insertApplyButton',
            label: 'Apply Button',
            withText: true
        })
    };

    // Add the item definition to the collection.
    itemDefinitions.add(applyButtonDefinition);

    const horizontalRuleDefinition = {
        type: 'button',
        model: new Model({
            commandParam: 'insertHorizontalRule',
            label: 'Horizontal Line',
            withText: true
        })
    };

    // Add the item definition to the collection.
    itemDefinitions.add(horizontalRuleDefinition);

    return itemDefinitions;
}