import { Controller } from "stimulus"

export default class extends Controller {
    initialize() {
        this.setPhonePrefix();
    }

    onKeyUp() {
        this.setPhonePrefix();
    }

    setPhonePrefix() {
        if (!this.element.value.startsWith('359')) {
            this.element.value = '359';
        }
    }
}