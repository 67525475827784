import Vue from 'vue';

Vue.component('post-link', {
    template: `<a href="" class="dropdown-item" @click.prevent="postAction"><i :class="iconClass"></i> <slot></slot></a>`,
    props: {
        targetUrl: {
            required: true,
            type: String
        },
        redirectUrl: {
            required: true,
            type: String
        },
        iconClass: {
            required: false,
            type: String,
            default: ''
        }
    },
    methods: {
        postAction() {
            let _self = this;

            $.ajax({
                method: 'POST',
                url: this.targetUrl,
                success() {
                    window.location.replace(_self.redirectUrl);
                },
                error(error) {
                    if (error['responseJSON']['error']) {
                        flash(error['responseJSON']['error'], 'error');
                    }
                }
            });
        }
    }
});