import Vue from 'vue';

Vue.component('cookie-declaration', {
    template: `<div id="CookieDeclaration"></div>`,
    mounted() {
        this.loadJs('https://consent.cookiebot.com/e15aaf9f-7788-4a45-96ee-cd51ae692650/cd.js', function() {
            console.log('Loaded cookie declaration script.');
        });
    },
    methods: {
        loadJs(url, callback) {
            $.ajax({
                url: url,
                dataType: 'script',
                success: callback,
                async: true
            });
        }
    }
});