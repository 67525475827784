import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "map" ];

    connect() {
        if (typeof(google) != "undefined") {
            this.initializeMap()
        }
    }

    initializeMap() {
        this._latSetting = parseFloat(this.mapTarget.dataset.lat)
        this._lngSetting = parseFloat(this.mapTarget.dataset.lng)
        this._zoomSetting = parseInt(this.mapTarget.dataset.zoom)
        this._address = this.mapTarget.dataset.address
        this._icon = this.mapTarget.dataset.icon

        this._mapLocation = {
            lat: this._latSetting,
            lng: this._lngSetting
        }

        let contentString = '<div class="info-window">' +
            '<div class="info-content">' +
            '<span>' + this._address + '</span>' +
            '</div>' +
            '</div>';

        let infowindow = new google.maps.InfoWindow({
            content: contentString,
            maxWidth: 400
        })

        let map = this.map()
        let marker = this.marker()

        infowindow.open(map, marker)

        let styles = [{"featureType": "landscape", "stylers": [{"saturation": -100}, {"lightness": 65}, {"visibility": "on"}]}, {"featureType": "poi", "stylers": [{"saturation": -100}, {"lightness": 51}, {"visibility": "off"}]}, {"featureType": "road.highway", "stylers": [{"saturation": -100}, {"visibility": "simplified"}]}, {"featureType": "road.arterial", "stylers": [{"saturation": -100}, {"lightness": 30}, {"visibility": "on"}]}, {"featureType": "road.local", "stylers": [{"saturation": -100}, {"lightness": 40}, {"visibility": "on"}]}, {"featureType": "transit", "stylers": [{"saturation": -100}, {"visibility": "simplified"}]}, {"featureType": "administrative.province", "stylers": [{"visibility": "off"}]}, {"featureType": "water", "elementType": "labels", "stylers": [{"visibility": "on"}, {"lightness": -25}, {"saturation": -100}]}, {"featureType": "water", "elementType": "geometry", "stylers": [{"hue": "#ffff00"}, {"lightness": -25}, {"saturation": -97}]}];
        map.set('styles', styles)
    }

    map() {
        if (this._map === undefined) {
            this._map = new google.maps.Map(this.mapTarget, {
                center: new google.maps.LatLng(
                    this._latSetting,
                    this._lngSetting
                ),
                scrollwheel: false,
                zoom: this._zoomSetting
            })
        }

        return this._map
    }

    marker() {
        if (this._marker === undefined) {
            this._marker = new google.maps.Marker({
                map: this.map(),
                icon: this._icon
            })
            this._marker.setPosition(this._mapLocation)
            this._marker.setVisible(true)
        }

        return this._marker
    }
}
