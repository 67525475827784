import Vue from 'vue';
import Multiselect from 'vue-multiselect'
import Style from 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.component('userMultiselect', {
    template: `#user-multiselect`,
    delimiters: ['[[', ']]'],
    components: {
        Multiselect
    },
    props: {
        mappedUsers: {
            required: false,
            type: Array,
            default() {
                return []
            }
        },
        searchUrl: {
            required: true,
            type: String,
            default: null
        }
    },
    data () {
        return {
            selectedUsers: [],
            users: [],
            isLoading: false
        }
    },
    mounted() {
        if(this.mappedUsers.length > 0){
            this.selectedUsers = this.mappedUsers;
        }
    },
    methods: {
        limitText (count) {
            return `and ${count} other results`
        },

        asyncFind (query) {
            this.isLoading = true
            var _self = this;
            $.ajax({
                url: _self.searchUrl,
                method: 'GET',
                data: {'query':query},
                success: function (response) {
                    _self.users = response;
                    _self.isLoading = false;
                },
                error: function (response) {
                    console.log(response);
                }
            });
        },
        clearAll () {
            this.selectedUsers = []
        }
    }
});
