import Vue from 'vue';

Vue.component('entity-translations-form', {
    delimiters: ['[[', ']]'],
    template: `
<div>
    <entity-translation v-for="locale in localesArray" :key="locale" :object-class="objectClass" :field="field" :foreign-key="foreignKey" :locale="locale"></entity-translation>
</div>
`,
    props: {
        objectClass: {
            required: true,
            type: String
        },
        foreignKey: {
            required: true,
            type: String
        },
        field: {
            required: true,
            type: String
        },
        locales: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            localesArray: [],
            translations: {}
        }
    },
    mounted() {
        this.localesArray = this.locales.split('|');
    }
});