import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    submit() {
        if (document.forms.poll.checkValidity() === false) {
            return;
        }

        let completedPolls = localStorage.getItem('completed_polls') || '',
            pollId = this.data.get('pollId');

        completedPolls = completedPolls.split('|');
        completedPolls.push(pollId);
        completedPolls = new Set(completedPolls);
        completedPolls = Array.from(completedPolls);

        localStorage.setItem('completed_polls', completedPolls.join('|'));
    }
}