export default class InsertApplyButton {
    constructor(editor) {
        this.editor = editor;
    }

    execute() {
        const linkHref = prompt('Button URL');

        this.editor.model.change(writer => {
            const buttonElement = writer.createElement('applyButton', {
                linkHref: linkHref
            });

            // Insert the image in the current selection location.
            this.editor.model.insertContent(buttonElement, this.editor.model.document.selection);
        });
    }

    refresh() {
    }
}