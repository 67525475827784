import {ApplyButtonEditing, ApplyButtonUI} from './ApplyButton';
import HorizontalRule from './HorizontalRule';
import WidgetsUI from './WidgetsUI';

export default class Widgets {
    constructor(editor) {
        this.editor = editor;
    }

    static get requires() {
        return [ApplyButtonEditing, HorizontalRule, ApplyButtonUI, WidgetsUI];
    }
}

