import Vue from 'vue';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Vue.component('files-list', {
    delimiters: ['[[', ']]'],
    template: `
<div>
    <vue-dropzone ref="customDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true><slot></slot></vue-dropzone>
    
    <ul class="list-group mt-2">
        <li class="list-group-item d-flex justify-content-between" v-for="image in images" :key="image.id">
            <a :href="image.publicPath" target="_blank">[[ image.originalName ]]</a>
            <span style="font-size: 1.2em">
                <span v-if="deletedFileIndex === image.id" class="badge badge-secondary">
                    Are you sure?
                    <span class="badge badge-pill badge-danger" style="cursor:pointer;" @click="deleteImage(image.id)">Yes</span>
                    <span class="badge badge-pill badge-light" style="cursor: pointer;" @click="deletedFileIndex = -1">No</span>
                </span>
                <span class="fa fa-trash-o text-danger" style="cursor: pointer" data-toggle="tooltip" data-placement="top" title="Delete the image" @click="deletedFileIndex = image.id"></span>
            </span>
        </li>
    </ul> 
</div>
`,
    components: {
        vueDropzone: vue2Dropzone
    },
    props: {
        createUrl: {
            required: true,
            type: String
        },
        indexUrl: {
            required: true,
            type: String
        },
        deleteUrl: {
            required: true,
            type: String
        },
        thumbnailWidth: {
            required: false,
            type: Number,
            default: 150
        }
    },
    mounted() {
        this.getImages();
    },
    data() {
        let _self = this;

        return {
            dropzoneOptions: {
                url: this.createUrl,
                thumbnailWidth: this.thumbnailWidth,
                addRemoveLinks: true,
                paramName: 'file',
                init() {
                    this.on('error', function(file, data) {
                        if (data.detail) {
                            flash(data.detail, 'error');
                        }
                    });

                    this.on('success', function(file, data) {
                        _self.images.push(data);

                        flash('Image was uploaded.', 'success');
                    });
                }
            },
            images: [],
            state: 'initial',
            deletedFileIndex: -1
        }
    },
    methods: {
        getImages() {
            let _self = this;

            $.ajax({
                method: 'GET',
                url: _self.indexUrl,
                success(response) {
                    _self.images = JSON.parse(response);
                },
                error(error) {
                    console.log(error);
                }
            });
        },
        deleteImage(id) {
            let _self = this,
                data = {image_id: id};

            $.ajax({
                method: 'DELETE',
                url: _self.deleteUrl,
                data: data,
                success() {
                    let imageIndex = _self.images.findIndex(item => item.id === id);

                    _self.images.splice(imageIndex, 1);

                    flash('Image was deleted', 'success');
                },
                error(error) {
                    console.log(error);
                }
            });
        }
    }
});