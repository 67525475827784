import Vue from 'vue';

Vue.component('login-modal', {
    template: '#login-modal',
    delimiters: ['[[', ']]'],
    data() {
        return {
            email: '',
            password: '',
            authError: '',
            formErrors: {
                email: false,
                password: false
            },
            loading: false,
            emailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            hidePassword: true,
        }
    },
    computed:{
        passwordInputType(){
            return (this.hidePassword) ? 'password' : 'text';
        },
        passwordInputIcon(){
            return (this.hidePassword) ? 'fa-eye' : 'fa-eye-slash';
        }
    },
    methods: {
        login() {
            if (this.validForm()) {
                this.loading = true;
                var formData = new FormData(this.$refs.loginForm);

                const self = this;

                $.ajax({
                    method: 'post',
                    url: '/login',
                    data: formData,
                    processData: false,
                    contentType: false,
                    success(response) {
                        window.location.reload();
                    },
                    error(error) {
                        var response = JSON.parse(error.responseText);
                        self.authError = response.message;
                    },
                    complete(){
                        self.loading = false
                    }
                });
            }
        },
        validForm() {
            this.formErrors.email = false;
            this.formErrors.password = false;

            if(!this.email || !this.emailRegex.test(this.email) || !this.password){
                if (!this.email || !this.emailRegex.test(this.email)) {
                    this.formErrors.email = true;
                }

                if (!this.password) {
                    this.formErrors.password = true;
                }

                return false;
            }

            return true;
        },
        togglePasswordVisibility() {
            this.hidePassword = !this.hidePassword
        },
    },
});