import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['fullInfo', 'buttonText', 'arrow'];

    connect() {
        if(this.data.get('expanded')){
            this.toggleInfo();
            this.element.scrollIntoView({behavior: "smooth", block: "nearest"});
        }else{
            this.buttonTextTarget.innerHTML = this.data.get('textDown');
        }
    }

    toggleInfo() {
        this.arrowTarget.classList.toggle('fa-angle-down');
        this.arrowTarget.classList.toggle('fa-angle-up');

        this.fullInfoTarget.classList.toggle('not-visible');

        this.buttonTextTarget.innerHTML = this.textOnButton;
    }

    get isOpen() {
        return this.arrowTarget.classList.contains('fa-angle-up');
    }

    get textOnButton() {
        return this.isOpen ? this.data.get('textUp') : this.data.get('textDown');
    }
}