import Vue from 'vue';

Vue.component('entity-translation', {
    delimiters: ['[[', ']]'],
    template: `
<div class="form-group">
    <label for="content" class="control-label text-uppercase text-primary font-weight-bolder">[[locale]]</label>
    <span class="fa fa-cloud-upload text-dark" @click="saveContent" style="font-size: 1.5em; cursor: pointer;"></span>
    <textarea name="content" class="form-control" rows="3" v-model="content"></textarea>
</div>
`,
    props: {
        objectClass: {
            required: true,
            type: String
        },
        foreignKey: {
            required: true,
            type: String
        },
        field: {
            required: true,
            type: String
        },
        locale: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            content: 'Loading...'
        }
    },
    mounted() {
        this.getContent();
    },
    methods: {
        getContent() {
            let url = `/admin/entity-translations/${encodeURI(this.objectClass)}/${this.foreignKey}/${this.field}/${this.locale}`,
                _self = this;

            $.ajax({
                method: 'GET',
                url: url,
                success(response) {
                    _self.content = response.content;
                },
                error(error) {
                    console.log(error);
                }
            });
        },
        saveContent() {
            let url = `/admin/entity-translations/${encodeURI(this.objectClass)}/${this.foreignKey}/${this.field}/${this.locale}`,
                _self = this,
                data = {content: this.content};

            $.ajax({
                method: 'POST',
                url: url,
                data: data,
                success() {
                    flash('Translation was updated.', 'success');
                },
                error(error) {
                    console.log(error);
                    flash('Translation could not be saved.', 'error');
                }
            });
        }
    }
});