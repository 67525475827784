import Vue from 'vue';

Vue.component('file-upload-button', {
    name: "FileUploadButton",
    delimiters: ['[[', ']]'],
    template: '#file-upload-button',
    props: {
        uploadUrl: {
            type: String,
            required: true
        },
        redirectUrl: {
            required: true,
            type: String
        },
        buttonText: {
            required: false,
            type: String,
            default: 'Upload'
        }
    },

    data() {
        return {
            uploading: false
        }
    },

    computed: {
        isUploading() {
            return this.uploading === true;
        }
    },

    methods: {
        selectFile() {
            this.$refs.fileInput.click();
        },

        uploadFile() {
            let file = this.$refs.fileInput.files[0],
                _self = this;

            if (!file) {
                return;
            }

            this.uploading = true;

            let formData = new FormData();
            formData.append("file", file);

            $.ajax({
                method: 'post',
                url: this.uploadUrl,
                data: formData,
                processData: false,
                contentType: false,
                success() {
                    _self.uploading = false;

                    window.location.replace(_self.redirectUrl);console.log(_self.redirectUrl);
                },
                error(error) {
                    _self.uploading = false;

                    flash(error['responseJSON'].detail, 'error');
                }
            });
        }
    }
});