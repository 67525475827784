import Vue from 'vue';

Vue.component('user-activity', {
    delimiters: ['[[', ']]'],
    template: '<div></div>',

    data() {
        return {
            interval: null
        }
    },
    methods: {
        updateActivity: function () {
            $.post('/activity', function (response) {
            }.bind(this));
        }
    },
    mounted: function () {
        this.updateActivity();

        this.interval = setInterval(function () {
            this.updateActivity();
        }.bind(this), 5000);
    },
    beforeDestroy: function() {
        clearInterval(this.interval);
    }
});