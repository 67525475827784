import Vue from 'vue';
import Flickity from 'flickity';
import 'flickity/dist/flickity.css';

Vue.component('carousel', {
    template: '#carousel',
    props: {
        wrapAround: {default: true},
        autoPlay: {default: false}
    },
    mounted() {
        new Flickity(this.$el, {
            wrapAround: this.wrapAround,
            autoPlay: this.autoPlay,
            cellAlign: 'left',
            contain: true
        });
    }
});