import Vue from 'vue';

Vue.component('user-invite-history', {
    delimiters: ['[[', ']]'],
    template: '#user-invite-history',
    props: {

    },
    data() {
        return {
            showHistory: false,
            isLoading: false,
            invites: [],
            limit: 10,
            offset: 0,
            moreInvites: true
        }
    },
    mounted() {
        this.getInvites();
    },
    computed:{
        showMoreInvitesButton(){
            return (this.showHistory && this.moreInvites);
        },
        moreInvitesButtonIcon(){
            return (this.isLoading) ? 'fa-spinner fa-pulse' : 'fa-sort-amount-asc';
        }
    },
    methods: {
        getInvites() {
            if(!this.isLoading){
                this.isLoading = true;

                let url = '/profile/get-invites',
                    _self = this;

                $.ajax({
                    url: url,
                    method: 'GET',
                    data: {'limit' : this.limit, 'offset' : this.offset},
                    success: function (response) {
                        if(JSON.parse(response).length < _self.limit) {
                            _self.moreInvites = false;
                        }
                        _self.invites = JSON.parse(response).concat(_self.invites);
                        _self.offset += _self.limit;
                        _self.isLoading = false;
                    },
                    error: function (response) {
                        console.log(response);
                    }
                });
            }
        },

        toggleHistory() {
           this.showHistory = !this.showHistory
        },
    },
});