import Vue from 'vue';
import Slider from 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Flickity from "flickity";

Vue.component('slider-module', {
    template: `<div class="carousel" :style="styles"><slot></slot></div>`,
    props: {
        centerPadding: {
            default: '15px',
            type: String,
            required: false
        },
        textColor: {
            default: 'white',
            required: false,
            type: String
        },
        size: {
            default: 'medium',
            required: false,
            type: String
        }
    },
    mounted() {
        this.createStyle();

        new Flickity(this.$el, {
            items: 1,
            wrapAround: true,
            autoPlay: false,
            pageDots: false,
            contain: true,
            setGallerySize: false,
        });
    },
    computed: {
        styles() {
            let styles = {};
            styles.color = this.textColor;

            if (this.size === 'small') {
                styles.height = '200px';
            } else if (this.size === 'medium') {
                styles.height = '400px';
            } else {
                styles.height = '600px';
            }

            return styles;
        }
    },
    methods: {
        createStyle() {
            let styleElement = document.createElement('style');
            let css = document.createTextNode(`
            .flickity-button-icon {
                fill: ${this.textColor};
            }
            .flickity-button:hover {
                  background: none;
                  cursor: pointer;
            }
            `);
            styleElement.append(css);

            document.querySelector('head').append(styleElement);
        }
    }
});