import Vue from 'vue';
import Slider from 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

Vue.component('slider', {
    template: `<div class="credit-item"><slot></slot></div>`,
    props: {
        infinite: {
            default: true,
            type: Boolean,
            required: false
        },
        slidesToShow: {
            default: 3,
            type: Number,
            required: false
        },
        slidesToScroll: {
            default: 3,
            type: Number,
            required: false
        },
        dots: {
            default: false,
            type: Boolean,
            required: false
        },
        centerPadding: {
            default: '15px',
            type: String,
            required: false
        },
    },
    mounted() {
        $(this.$el).slick({
            // normal options...
            infinite: this.infinite,
            slidesToShow: this.slidesToShow,
            slidesToScroll: this.slidesToScroll,
            dots: this.dots,
            centerPadding: this.centerPadding,

            // the magic
            responsive: [{

                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerPadding: '15px',
                }

            }, {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '15px',
                }
            },
            {
                breakpoint: 200,
                settings: "unslick" // destroys slick
            }
            ]
        });
    }
});