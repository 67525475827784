import Vue from 'vue';

Vue.component('poll-participation', {
    delimiters: ['[[', ']]'],
    template: '#poll-participation',
    props: {},
    data() {
        return {
            activePolls: {},
            selectedPoll: null,
            show: true
        }
    },
    mounted() {
        if (window.location.href.indexOf('/poll') >= 0 || window.location.href.indexOf('/admin') >= 0) {
            this.show = false;
            return;
        }

        setTimeout(this.getActivePolls, 3000);
    },
    methods: {
        getActivePolls() {
            let url = '/poll/active',
                _self = this;

            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {
                    _self.activePolls = response.map(item => item.id);
                    _self.selectPoll();
                },
                error: function (response) {
                    console.log(response);
                }
            });
        },

        selectPoll() {
            let completedPolls = localStorage.getItem('completed_polls') || '',
                notCompletedPolls;

            if (this.activePolls.length === 0) {
                return;
            }

            if (!completedPolls) {
                this.selectedPoll = this.activePolls[0];
                return;
            }

            completedPolls = completedPolls.split('|');
            notCompletedPolls = this.activePolls.filter(poll => completedPolls.findIndex(item => parseInt(item) === poll) < 0);
            this.selectedPoll = notCompletedPolls.pop();
        },

        declinePoll() {
            if (!this.selectedPoll) {
                return;
            }

            let completedPolls = localStorage.getItem('completed_polls') || '';
            completedPolls = completedPolls.split('|');
            completedPolls.push(this.selectedPoll);
            localStorage.setItem('completed_polls', completedPolls.join('|'));
            this.close();
        },

        showPoll() {
            window.location.href = `/poll/${this.selectedPoll}/complete`;
        },

        close() {
            $('#poll_participation').modal('hide');
        },

        open() {
            if (this.show) {
                $('#poll_participation').modal('show');
            }
        }
    },
    watch: {
        selectedPoll: function (newValue) {
            if (newValue) {
                this.open();
            }
        }
    }
});