import {Controller} from "stimulus";

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Table from '@ckeditor/ckeditor5-table/src/table';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import CKFinderAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import CustomUploadAdapter from './CustomUploadAdapter';
import InsertImage from './CKEditor/InsertImage';
import Widgets from './CKEditor/Widgets';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical';
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';

export default class extends Controller {
    initialize() {
        this.uploadUrl = this.element.getAttribute("data-upload-url");
    }

    connect() {
        ClassicEditor
            .create(this.element, {
                height: 500,
                plugins: [
                    Heading, EssentialsPlugin,
                    BoldPlugin, ItalicPlugin, LinkPlugin,
                    ParagraphPlugin,
                    List,
                    Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageUpload, InsertImage, LinkImage,
                    MediaEmbed,
                    Table, TableToolbar, TableProperties, TableCellProperties,
                    CKFinder,
                    CKFinderAdapter,
                    BlockQuote,
                    Alignment, Font,
                    Widgets,
                    SpecialCharacters, SpecialCharactersEssentials, SpecialCharactersMathematical, SpecialCharactersCurrency
                ],
                alignment: {
                    options: [ 'left', 'right', 'center', 'justify' ]
                },
                toolbar: ['heading', '|', 'bold', 'italic', 'alignment', '|', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|', 'specialCharacters', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo', '|', 'widgets'],
                extraPlugins: [myCustomUploadAdapterPlugin],
                customUploadUrl: this.uploadUrl,
                image: {
                    toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignCenter', 'imageStyle:alignRight', 'linkImage'],
                    styles: [
                        'full',
                        'side',
                        'alignLeft',
                        'alignCenter',
                        'alignRight',
                    ]
                },
                mediaEmbed: {
                    previewsInData: true,
                },
                link: {
                    decorators: {
                        isExternal: {
                            mode: 'manual',
                            label: 'Open in a new tab',
                            attributes: {
                                target: '_blank'
                            }
                        }
                    }
                },
                fontFamily: {
                    options: [
                        'default',
                        'Arial, Helvetica, sans-serif',
                        'Courier New, Courier, monospace',
                        'Georgia, serif',
                        'Lucida Sans Unicode, Lucida Grande, sans-serif',
                        'Tahoma, Geneva, sans-serif',
                        'Times New Roman, Times, serif',
                        'Trebuchet MS, Helvetica, sans-serif',
                        'Verdana, Geneva, sans-serif',
                        'Ubuntu'
                    ]
                },
                table: {
                    contentToolbar: [
                        'tableColumn', 'tableRow', 'mergeTableCells',
                        'tableProperties', 'tableCellProperties'
                    ],

                    // Configuration of the TableProperties plugin.
                    tableProperties: {
                        // ...
                    },

                    // Configuration of the TableCellProperties plugin.
                    tableCellProperties: {
                        // ...
                    }
                },
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
                    ]
                },
                fontSize: {
                    options: [
                        9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24
                    ]
                },
            })
            .then(editor => {
                console.log('CKEDITOR is ready.');
            })
            .catch(error => {
                console.error(error);
            });
    }
}

function myCustomUploadAdapterPlugin(editor) {
    let uploadUrl = editor.config.get('customUploadUrl');

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new CustomUploadAdapter(loader, uploadUrl);
    };
}