import Vue from 'vue';
import Slider from 'ion-rangeslider';
import 'ion-rangeslider/css/ion.rangeSlider.css';

Vue.component('credit-offer', {
    delimiters: ['[[', ']]'],
    template: '#credit-offer',
    props: {
        index: {
            required: true,
            type: Number
        },
        currency: {
            required: false,
            type: String,
            default: 'MKD'
        },
        type: {
            required: false,
            type: String,
            default: 'personal'
        },
        amount: {
            required: false,
            type: String,
            default: 0
        },
        payment: {
            required: false,
            type: String,
            default: 0
        },
        paymentWithPenalty: {
            required: false,
            type: String,
            default: 0
        },
        periods: {
            required: false,
            type: Number,
            default: 0
        },
        duration: {
            required: false,
            type: Number,
            default: 0
        },
        total: {
            required: false,
            type: String,
            default: 0
        },
        totalWithPenalty: {
            required: false,
            type: String,
            default: 0
        },
        interestRateFirstPeriod: {
            required: false,
            type: String,
            default: 0
        },
        interestRateSecondPeriod: {
            required: false,
            type: String,
            default: 0
        },
        aprc: {
            required: false,
            type: String,
            default: ''
        },
        paymentSchedule: {
            required: false,
            type: String,
            default: 'weekly'
        },
        pensioner: {
            required: false,
            type: String,
            default: 'no'
        },
        title: {
            required: false,
            type: String,
            default: 'iCredit Weekly'
        },
        slug: {
            required: false,
            type: String,
            default: 'icredit-weekly'
        },
        requirements: {
            required: false,
            type: String,
            default: 'only ID card needed'
        }
    },
    methods: {
        close() {
            EventManager.fire('remove-credit-offer', this.index);
        },

        apply() {
            window.location.href = `/credit/apply/${this.slug}?amount=${this.amount}`;
        }
    }
});