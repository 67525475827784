import { Controller } from "stimulus"

export default class extends Controller {
    initialize() {
        this.parameter = this.data.get("parameter");
    }

    connect() {
        let _self = this;

        this.element.addEventListener('change', function () {
            let currentUrl = window.location.href,
                newUrl = '',
                regex, hasExistingValue;

            if (currentUrl.indexOf('?') < 0) {
                newUrl = `${currentUrl}?${_self.parameter}=${_self.element.value}`;

                return window.location.href = newUrl;
            }

            let pageRegex = new RegExp(/(page)=\d+/);
            currentUrl = currentUrl.replace(pageRegex, '$1=1');

            regex = new RegExp(`${_self.parameter}=`);
            hasExistingValue = currentUrl.match(regex);

            if (hasExistingValue) {
                let parts = currentUrl.split('?')[1].split('&');

                parts = parts.map(part => {
                    if (part.split('=')[0] === _self.parameter) {
                        return `${_self.parameter}=${_self.element.value}`;
                    }

                    return part;
                });

                newUrl = currentUrl.split('?')[0] + '?' + parts.join('&');

                return window.location.href = newUrl;
            } else {
                newUrl = `${currentUrl}&${_self.parameter}=${_self.element.value}`;

                return window.location.href = newUrl;
            }
        })
    }
}