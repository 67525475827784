import Vue from 'vue';

Vue.component('poll-answer', {
    delimiters: ['[[', ']]'],
    template: '#poll-answer',
    props: {
        answer: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            isEditing: false,
            deleteState: 'initial'
        }
    },
    methods: {
        getSaveData() {
            return {
                text: this.answer.text,
                hasComment: this.answer.hasComment,
                commentHelpText: this.answer.hasComment ? this.answer.commentHelpText : null
            }
        },

        save() {
            let url = `/admin/answers/${this.answer.id}/update`,
                _self = this,
                data = this.getSaveData();

            $.ajax({
                method: 'POST',
                data: data,
                url: url,
                success(response) {
                    _self.isEditing = false;
                    flash(response, 'success');
                },
                error(error) {
                    _self.isEditing = false;
                    flash(error['responseJSON'], 'error');
                }
            });
        },

        deleteAnswer() {
            let url = `/admin/answers/${this.answer.id}/delete`,
                _self = this;

            $.ajax({
                method: 'POST',
                url: url,
                success(response) {
                    _self.isEditing = false;

                    _self.changeDeleteState('initial');

                    _self.$emit('answerWasDeleted', _self.answer.id);

                    flash(response, 'success');
                },
                error(error) {
                    _self.isEditing = false;
                    flash(error['responseJSON'], 'error');
                }
            });
        },

        changeDeleteState(newState) {
            this.deleteState = newState;
        }
    }
});