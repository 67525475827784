import Vue from 'vue';

Vue.component('flashes', {
    template: '#flashes',
    props: {
        'flashes': {
            required: false,
            type: String,
            default: null
        }
    },
    data() {
        return {
            messages: []
        }
    },
    created() {
        let flashData = JSON.parse(this.flashes);

        for (let key in flashData) {
            flashData[key].forEach(message => this.messages.push({message: message, level: key}));
        }

        window.EventManager.listen(
            'flash', messageData => this.addMessage(messageData)
        );
    },
    methods: {
        addMessage(messageData) {
            this.messages.push(messageData)
        }
    }
});