import Vue from 'vue';
import Sortable from "sortablejs"

Vue.component('poll-question', {
    delimiters: ['[[', ']]'],
    template: '#poll-question',
    props: {
        question: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            isEditing: false,
            isOpen: false,
            isAdding: false,
            deleteState: 'initial',
            newAnswer: {
                hasComment: false
            },
            sortable: null,
        }
    },

    methods: {
        getSaveData() {
            return {
                text: this.question.text,
                type: this.question.type
            }
        },

        getCreateData() {
            return {
                text: this.newAnswer.text,
                hasComment: this.newAnswer.hasComment,
                commentHelpText: this.newAnswer.hasComment ? this.newAnswer.commentHelpText : null,
                questionId: this.question.id
            }
        },

        save() {
            let url = `/admin/questions/${this.question.id}/update`,
                _self = this,
                data = this.getSaveData();

            $.ajax({
                method: 'POST',
                data: data,
                url: url,
                success(response) {
                    _self.isEditing = false;
                    flash(response, 'success');
                },
                error(error) {
                    _self.isEditing = false;
                    flash(error['responseJSON'], 'error');
                }
            });
        },

        toggleOpen() {
            this.isOpen = !this.isOpen;
        },

        deleteQuestion() {
            let url = `/admin/questions/${this.question.id}/delete`,
                _self = this;

            $.ajax({
                method: 'POST',
                url: url,
                success(response) {
                    _self.isEditing = false;

                    _self.changeDeleteState('initial');

                    _self.$emit('questionWasDeleted', _self.question.id);

                    flash(response, 'success');
                },
                error(error) {
                    _self.changeDeleteState('initial');

                    flash(error['responseJSON'], 'error');
                }
            });
        },

        onAnswerDeleted(id) {
            let index = this.question.answers.findIndex(answer => answer.id === id);

            this.question.answers.splice(index, 1);
        },

        changeDeleteState(newState) {
            this.deleteState = newState;
        },

        createAnswer() {
            let url = `/admin/answers/new`,
                data = this.getCreateData(),
                _self = this;

            $.ajax({
                method: 'POST',
                data: data,
                url: url,
                success(response) {
                    _self.isAdding = false;

                    _self.question.answers.push(response['payload']);

                    _self.resetAnswer();

                    flash(response['message'], 'success');
                },
                error(error) {
                    console.log(error);
                }
            });
        },

        resetAnswer() {
            this.newAnswer = {
                text: '',
                hasComment: false,
                commentHelpText: '',
                answers: []
            };
        },

        toggleSorting() {
            this.sorting = !this.sorting;
        },

        sortAnswers(data) {
            let url = `/admin/questions/${this.question.id}/sort-answers`;

            $.ajax({
                method: 'POST',
                data: data,
                url: url,
                success(response) {
                    flash(response, 'success');
                },
                error(error) {
                    console.log(error);
                }
            });
        }
    },

    computed: {
        showAnswers() {
            return !this.isEditing && this.isOpen;
        }
    },

    watch: {
        showAnswers: function (newValue) {
            let _self = this;

            if (newValue) {
                this.sortable = Sortable.create(this.$refs.answers, {
                    ghostClass: 'blue-background-during-drag',
                    'handle': '.sort-handle',
                    animation: 300,
                    onSort: function () {
                        let data = [];
                        _self.$refs.answers.children.forEach(item => data.push(item.getAttribute('data-id')));

                        _self.sortAnswers({sortedAnswers: data});
                    }
                });
            } else {
                this.sortable = null;
            }
        }
    }
});