export default class InsertHorizontalRule {
    constructor(editor) {
        this.editor = editor;
    }

    execute() {
        this.editor.model.change(writer => {
            const horizontalRuleElement = writer.createElement('horizontalRule');

            // Insert the hr at the end of the selection
            writer.insert(horizontalRuleElement, this.editor.model.document.selection.focus);
            writer.setSelection(horizontalRuleElement, 'after');
        });
    }

    refresh() {
    }
}