import Vue from 'vue';
import Slider from 'ion-rangeslider';
import 'ion-rangeslider/css/ion.rangeSlider.css';

Vue.component('credit-comparison', {
    delimiters: ['[[', ']]'],
    template: '#credit-comparison',
    props: {
        paymentSchemeVisible: {
            required: false,
            type: Boolean,
            default: true
        },
        pensionerVisible: {
            required: false,
            type: Boolean,
            default: true
        },
        interestRateVisible: {
            required: false,
            type: Boolean,
            default: true
        },
        width: {
            required: false,
            type: String,
            default: 'full'
        },
    },
    data() {
        return {
            offers: []
        }
    },
    mounted() {
        EventManager.listen('create-credit-offer', this.createOffer);
        EventManager.listen('remove-credit-offer', this.removeOffer);
        EventManager.listen('credit-type-changed', this.removeOffer);
    },
    methods: {
        createOffer(data) {
            if (this.offers.length >= 2) {
                return;
            }

            this.offers.push(data)
        },

        removeOffer(index) {
            if (index === null) {
                this.offers = [];
            }

            this.offers.splice(index, 1);
        }
    }
});