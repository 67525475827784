import {toWidget} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import InsertApplyButton from "./InsertApplyButton";

export class ApplyButtonUI {
    constructor(editor) {
        this.editor = editor;
    }

    init() {
        const editor = this.editor;

        // The "applyButton" button must be registered among the UI components of the editor
        // to be displayed in the toolbar.
        editor.ui.componentFactory.add('applyButton', locale => {

            // The button will be an instance of ButtonView.
            const buttonView = new ButtonView(locale);

            buttonView.set({
                label: 'Apply Button',
                withText: true,
                tooltip: true
            });

            buttonView.on('execute', () => {
                this.editor.execute('insertApplyButton')
            });

            return buttonView;
        });
    }
}

export class ApplyButtonEditing {
    constructor(editor) {
        this.editor = editor;
    }

    static get requires() {
        return [Widget];
    }

    init() {
        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add('insertApplyButton', new InsertApplyButton(this.editor));
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('applyButton', {
            // Behaves like a self-contained object (e.g. an image).
            isObject: true,

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block',

            allowAttributes: ['linkHref']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        // <applyButton> converters
        conversion.for('upcast').elementToElement({
            view: {
                name: 'div',
                classes: ['applyButton']
            },
            model: (viewElement, modelWriter) => {
                // Extract the "linkHref".
                const linkHref = viewElement.getChild(0).getAttribute('href');

                return modelWriter.createElement('applyButton', {linkHref});
            }
        });

        conversion.for('editingDowncast').elementToElement({
            model: 'applyButton',
            view: (modelItem, viewWriter) => {
                const widgetElement = createApplyButtonView(modelItem, viewWriter);

                // Enable widget handling on a placeholder element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'applyButton',
            view: createApplyButtonView
        });

        // Helper method for both downcast converters.
        function createApplyButtonView(modelItem, viewWriter) {
            const linkHref = modelItem.getAttribute('linkHref');

            // Create the container div
            const applyButtonView = viewWriter.createContainerElement('div', {
                class: 'applyButton',
            });

            // Create the button
            const theButton = viewWriter.createContainerElement('a', {
                class: 'btn btn-outline-danger',
                href: linkHref
            });

            // Insert the button's text.
            const innerText = viewWriter.createText('Aplica acum!');
            viewWriter.insert(viewWriter.createPositionAt(theButton, 0), innerText);

            // Insert the button
            viewWriter.insert(viewWriter.createPositionAt(applyButtonView, 0), theButton);

            return applyButtonView;
        }
    }
}