import {toWidget} from '@ckeditor/ckeditor5-widget/src/utils';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import InsertHorizontalRule from './InsertHorizontalRule';

export default class HorizontalRule {
    constructor(editor) {
        this.editor = editor;
    }

    init() {
        const editor = this.editor;
        const model = editor.model;
        const conversion = editor.conversion;
        const schema = model.schema;

        this.editor.commands.add('insertHorizontalRule', new InsertHorizontalRule(this.editor));

        // Configure new horizontal rule schema
        schema.register('horizontalRule', {
            allowIn: '$root',
            isObject: true,
            isBlock: true,
        });

        conversion.for('upcast').elementToElement({
            view: {
                name: 'hr',
                classes: ['horizontalRule']
            },
            model: (viewElement, modelWriter) => {
                return modelWriter.createElement('horizontalRule');
            }
        });

        conversion.for('editingDowncast').elementToElement({
            model: 'horizontalRule',
            view: (modelItem, viewWriter) => {
                const widgetElement = createHorizontalRuleView(modelItem, viewWriter);

                // Enable widget handling on a placeholder element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'horizontalRule',
            view: createHorizontalRuleView
        });

        // Helper method for both downcast converters.
        function createHorizontalRuleView(modelItem, viewWriter) {

            return viewWriter.createContainerElement('hr', {class: 'horizontalRule'});
        }

        editor.ui.componentFactory.add('horizontalRule', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Horizontal Line',
                withText: true,
                tooltip: true
            });

            // Callback executed once the button is clicked.
            view.on('execute', () => {
                this.editor.execute('insertHorizontalRule')
            });

            return view;
        });
    }
}