import Vue from 'vue';

Vue.component('delete-link', {
    template: `<a href="" class="dropdown-item text-danger" @click.prevent="deleteAction"><i :class="iconClass"></i> <slot></slot></a>`,
    props: {
        targetUrl: {
            required: true,
            type: String
        },
        redirectUrl: {
            required: true,
            type: String
        },
        confirmationMessage: {
            required: false,
            type: String,
            default: 'Please, confirm you want to delete this item!'
        },
        iconClass: {
            required: false,
            type: String,
            default: 'fa fa-trash-o'
        }
    },
    methods: {
        deleteAction() {
            let _self = this;

            if(confirm(this.confirmationMessage)){
                $.ajax({
                    method: 'DELETE',
                    url: this.targetUrl,
                    success() {
                        window.location.replace(_self.redirectUrl);
                    },
                    error(error) {
                        console.log(error);
                        window.location.replace(_self.redirectUrl);
                    }
                });
            }
        }
    }
});